<template>
  <div class="process">
    <div class="process-container">
        <ul class="process1">
            <li>
            向县级红会递交申请资料
            <span></span>
            </li>
            <li>
            各级红会逐级审核资料
            <span></span>
            </li>
            <li>中国红基会评审公示</li>
        </ul>
        <div class="arrow"></div>
        <ul class="process2">
            <li>
            向受助人拨付资助款
            <span></span>
            </li>
            <li>
            患儿家属递交回执发票
            <span></span>
            </li>
            <li>寄发《资助告知书》</li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Process',
  props: {
      smallImage: {
          type:String,
          default: ""
      }
  }
}
</script>

<style lang="less" scoped>
.process {
    .process-container {
        position: relative;
        width: 1003px;
        min-height: 203px;
        // background-color: skyblue;
        margin: 0 auto;
        margin-top: 51px;
        .smallImage {
           width: 1003px; 
           min-height: 203px;
        //    background-color: pink;
        }
        .process1 {
            width: 945px;
            min-height: 156px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 29px;
            // background-color: pink;
            li {
                width: 213px;
                height: 48px;
                background-color: #ffffff;
                color: #106ABF;
                text-align: center;
                line-height: 48px;
                font-weight: bold;
                position: relative;
                border-radius: 24px;
                margin-right: 152px;
                margin-bottom: 90px;
                box-shadow: 0px 9px 7px 0px rgba(0, 0, 0, 0.21);
                span {
                    // content: "";
                    display: block;
                    width: 138px;
                    height: 0px;
                    top: 25px;
                    right: -141px;
                    position: absolute;
                    // border: 1px solid #666666;
                    border: 1px dashed #3E7DE5;
                    &::after {
                        content: "";
                        position: relative;
                        top: -13px;
                        left: 137px;
                        display: block;
                        width: 12px;
                        height: 25px;
                        background: url('../../../../assets/img/jiantou.png') no-repeat center center;
                    }
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                &:nth-child(3) {
                    margin-right: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .process2 {
            width: 945px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 29px;
            // background-color: pink;
            li {
                position: relative;
                width: 213px;
                height: 48px;
                background-color: #ffffff;
                color: #106ABF;
                text-align: center;
                line-height: 48px;
                font-weight: bold;
                border-radius: 24px;
                margin-right: 152px;
                box-shadow: 0px 9px 7px 0px rgba(0, 0, 0, 0.21);
                span {
                position: absolute;
                display: block;
                width: 138px;
                height: 0px;
                top: 25px;
                right: -151px;
                position: absolute;
                // border: 1px solid #666666;
                border: 1px dashed #3E7DE5;
                &::before {
                content: "";
                position: relative;
                top: -13px;
                left: -10px;
                display: block;
                width: 12px;
                height: 25px;
                background: url('../../../../assets/img/jiantou.png') no-repeat center center;
                -moz-transform:rotate(180deg); 
                -webkit-transform:rotate(180deg);
                transform:rotate(180deg);
                filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
                }
                }

                &:nth-child(3) {
                    margin-right: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .arrow {
            width: 0px;
            height: 92px;
            position: absolute;
            top: 50px;
            right: 134px;
            border: 1px dashed #3E7DE5;
            &::after {
                content: "";
                display: block;
                position: relative;
                bottom: -84px;
                right: 6px;
                width: 12px;
                height: 25px;
                background: url('../../../../assets/img/jiantou.png') no-repeat center center;
                -moz-transform:rotate(90deg); 
                -webkit-transform:rotate(90deg);
                transform:rotate(90deg);
                filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
            }
        }
    }
}
</style>
